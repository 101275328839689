<template>
  <div class="content-container">
    <BaseH1 :text="'Dziękujemy'" :short="true" />
    <SvgIllustrationsTick />
    <TextDescription v-html="description" />
    <TextHint :text="redirectMessage" />
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { ROUTE_REGISTRATION_WELCOME_NAME } from "@/router/constants";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import SvgIllustrationsTick from "@/components/Svg/Illustrations/SvgIllustrationsTick.vue";
import TextHint from "@/components/UI/TextHint.vue";

const TIME_IN_MS_BEFORE_REDIRECT = 5000;
const ROUTE_WELCOME = {
  name: ROUTE_REGISTRATION_WELCOME_NAME,
};

export default {
  components: {
    BaseH1,
    TextDescription,
    SvgIllustrationsTick,
    TextHint,
  },

  setup() {
    const userStore = useUserStore();
    const { isAuth } = storeToRefs(userStore);

    const description = computed(() => {
      if (!isAuth.value) {
        return "Twój adres email jest już \nzweryfikowany. \nZaloguj się,\naby korzystać z serwisu";
      }
      return "Twój adres email jest \n<b>zweryfikowany</b>";
    });
    const redirectCounter = ref(TIME_IN_MS_BEFORE_REDIRECT);
    const redirectMessage = computed(() => {
      const counter = (redirectCounter.value / 1000).toFixed(0);
      return `Przekierowanie do serwisu za ${counter}....`;
    });

    const handleDelayedRedirection = async () => {
      const intervalId = setInterval(redirectCountdown, 1000);
      await setRedirectTimer();
      clearInterval(intervalId);
    };

    const redirectCountdown = () => {
      redirectCounter.value -= 1000;
    };

    const setRedirectTimer = async () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(redirectToWelcome());
        }, TIME_IN_MS_BEFORE_REDIRECT);
      });
    };

    const router = useRouter();
    const redirectToWelcome = () => {
      router.push(ROUTE_WELCOME);
    };

    onMounted(() => {
      if (isAuth.value) {
        handleDelayedRedirection();
      }
    });

    return {
      description,
      redirectMessage,
    };
  },
};
</script>

<style scoped>
.content-container {
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 80px;
}

@media (max-width: 1200px) {
  .content-container {
    margin: auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    row-gap: 80px;
  }
}
</style>
